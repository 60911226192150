import React from "react";

import { ShortcutProvider } from "react-keybind";
import { SWRConfig } from "swr";

import { UserProvider } from "../hooks/UserProvider";

import "tailwindcss/tailwind.css";

const fetcher = async (url) => {
  const res = await fetch(url);

  if (!res.ok) {
    const error = new Error("An error occurred while fetching the data.");

    error.info = await res.json();
    error.status = res.status;
    throw error;
  }

  return res.json();
};

function App({ Component, pageProps }) {
  return (
    <SWRConfig value={{ fetcher }}>
      <UserProvider>
        <ShortcutProvider>
          <Component {...pageProps} />
        </ShortcutProvider>
      </UserProvider>
    </SWRConfig>
  );
}

export default App;
