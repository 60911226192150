import { createContext, useContext, useEffect, useState } from "react";
import { customAlphabet } from "nanoid";

const nanoid = customAlphabet("1234567890abcdefghijklmnopqrstuvwxyz", 10);

const UserContext = createContext();

export const useUser = () => {
  return useContext(UserContext);
};

export const WithUser = ({ children }) => {
  const user = useUser();

  return typeof children === "function" ? children({ user }) : children;
};

export const UserProvider = ({ children }) => {
  const [username, setUsername] = useState("");
  const [userId, setUserId] = useState("");
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    try {
      const u = localStorage.getItem("username");
      setUsername(u || "");
      const id = localStorage.getItem("userId");
      if (!id) {
        const userId = nanoid();
        localStorage.setItem("userId", userId);
        setUserId(userId);
      } else {
        setUserId(id);
      }
    } catch {
      console.error("could not get username from localStorage");
    }

    setInitialized(true);
  }, []);

  if (!initialized) {
    return null;
  }

  const changeUsername = (newUsername) => {
    localStorage.setItem("username", newUsername);
    setUsername(newUsername);
  };

  return (
    <UserContext.Provider value={{ username, userId, changeUsername }}>
      {children}
    </UserContext.Provider>
  );
};
